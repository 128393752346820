<template>
  <button
    class="transition rounded hover:bg-grey-lighter p-2 flex"
    @click="$emit('click')"
  >
    <img
      :src="require(`@/assets/img/icons/${icon}.svg`)"
      :alt="icon"
    >
  </button>
</template>

<script>

export default {
  name: 'IconButton',
  components: { },
  emits: [ 'click' ],
  props: {
    icon: {
      type: String,
      default: ''
    },
    width: {
      type: Number,
      default: 24
    }
  }
}
</script>

<style scoped>

</style>
