<template>
  <button
    class="flex items-center rounded pr-2 text-left"
    @click="$emit('click')"
  >
    <span class="w-5 h-5 flex-none inline-block border border-grey-d1 rounded mr-4" />
    <span class="mr-3">{{ task.name }}</span>

    <icon
      class="hidden edit-icon"
      icon="edit-alt"
    />
  </button>
</template>

<script>
import Icon from './Icon'
export default {
  name: 'TaskListItem',
  components: { Icon },
  props: {
    task: {
      type: Object,
      required: true
    }
  },
}
</script>

<style scoped>
  button {
    outline-offset: 1px;
  }

  button:hover > .edit-icon,
  button:focus > .edit-icon {
    display: block;
  }

</style>
