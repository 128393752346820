<template>
  <div>
    <h2>Workflow settings</h2>
    <div class="mt-16">
      <form-group
        label="Workflow name:"
      >
        <form-input
          v-model="name"
          type="text"
          class="w-full"
          placeholder="E.g. Destination Photography"
        />
      </form-group>
      <form-group
        label="Color:"
        alignment="top"
      >
        <v-alt-color-picker v-model="color" />
      </form-group>
    </div>
    <div class="flex justify-end mt-8 border-t border-grey-lighter pt-4">
      <button
        class="grey-btn mr-4"
        @click="$emit('cancel')"
      >
        Cancel
      </button>
      <button
        class="green-btn"
        @click="update"
      >
        Save
      </button>
    </div>
  </div>
</template>

<script>
import FormGroup from './FormGroup'
import FormInput from './FormInput'
import VAltColorPicker from './VAltColorPicker/VAltColorPicker'
import { updateWorkflow } from '../api/Workflows'

export default {
  name: 'WorkflowEditor',
  components: { VAltColorPicker, FormInput, FormGroup },
  emits: ['save', 'cancel'],
  props: {
    workflow: {
      type: Object,
      required: true
    }
  },
  data: function () {
    return {
      name: this.workflow.name,
      color: this.workflow.color
    }
  },
  methods: {
    async update() {
      const res = this.handleErrors(
        await updateWorkflow(this.workflow.id, { name: this.name, color: this.color })
      )

      if (!res) return

      this.$emit('updated', res)
      this.$emit('saved', res)
    }
  }
}
</script>

<style scoped>

</style>
