<template>
  <button
    class="pr-2 flex items-center rounded-full transition bg-white"
    :class="{
      'pr-2': action.automatic,
      'pr-4': !action.automatic,
    }"
    @click="$emit('click')"
  >
    <span
      class="circle"
    >
      {{ number }}
    </span>
    <span class="text-left flex-1 min-w-0">
      {{ action.name }}
    </span>
    <badge
      v-if="action.automatic"
      class="ml-3"
      icon="refresh-white"
    >
      AUTO
    </badge>
    <icon
      class="ml-3 edit-icon"
      icon="edit-alt"
    />
  </button>
</template>

<script>
import Badge from './Badge'
import Icon from './Icon'
export default {
  name: 'ActionListItem',
  components: { Icon, Badge },
  emits: ['click'],
  props: {
    number: {
      type: Number,
      default: 0,
    },
    action: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped>
  button {
    outline-offset: 1px;
  }

  .circle {
    @apply text-xs flex items-center justify-center border border-grey-df rounded-full mr-2;
    height: 31px;
    width: 31px
  }

  button > .edit-icon {
    opacity: 0;
  }

  button:hover > .edit-icon,
  button:focus > .edit-icon {
    opacity: 1;
  }
</style>
