<template>
  <select disabled>
    <option value="">Loading...</option>
  </select>
</template>

<script>
export default {
  name: 'LoadingSelect'
}
</script>

<style scoped>

</style>
