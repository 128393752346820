<template>
  <div class="select-container">
    <select
      v-if="!loading"
      :value="value"
      @input="$emit('input', $event.target.value)"
    >
      <option :value="null">
        Select template
      </option>
      <option
        v-for="template in proposalTemplates"
        :key="template.id"
        :value="template.id"
      >
        {{ template.name }}
      </option>
    </select>

    <loading-select
      v-if="loading"
    />
  </div>
</template>

<script>
import auth from '../auth'
import LoadingSelect from './LoadingSelect'

export default {
  name: 'SelectProposalTemplate',
  components: { LoadingSelect },
  emits: ['input'],
  props: {
    value: {
      type: [String, Number],
      default: null
    }
  },
  data: function () {
    return {
      proposalTemplates: [],
      loading: true
    }
  },
  mounted() {
    this.loadProposalTemplates()
  },
  methods: {
    async loadProposalTemplates() {
      const { data } = await this.$api.get('templateProposal').list(auth.user().company.id)
      this.proposalTemplates = data.proposals
      this.loading = false
    }
  }
}
</script>

<style scoped>

</style>
