<template>
  <button
    class="text-sm text-sm green border-b border-green"
    @click="$emit('click')"
  >
    <slot />
  </button>
</template>

<script>
export default {
  name: 'TaskListButton'
}
</script>

<style scoped>

</style>
