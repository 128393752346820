<template>
  <div>
    <h2 class="font-bold">
      <span class="font-normal">{{ phase.name }} － </span>
      <span v-if="task">Edit task</span>
      <span v-else>Add task</span>
    </h2>
    <div class="mt-16">
      <form-group
        label="Title:"
        class="relative"
      >
        <form-input
          v-model="name"
          type="text"
          class="w-full"
          placeholder="E.g. schedule appointment with client"
        />
      </form-group>

      <form-group
        label="Due date:"
      >
        <div class="flex items-center">
          <form-input
            v-if="scheduleType !== null"
            v-model="scheduleDateValue"
            type="number"
            class="mr-2"
          />

          <div
            class="mr-2 select-container"
          >
            <select
              v-model="scheduleDateType"
            >
              <option
                v-for="dateType in scheduleDateTypes"
                :key="dateType.value"
                :value="dateType.value"
              >
                {{ dateType.label }}
              </option>
            </select>
          </div>
          <div
            class="select-container flex-1"
          >
            <select
              v-model="scheduleType"
            >
              <option
                v-for="type in scheduleTypes"
                :key="type.value"
                :value="type.value"
              >
                {{ type.label }}
              </option>
            </select>
          </div>
        </div>
      </form-group>
    </div>

    <div class="flex justify-end mt-8 border-t border-grey-lighter pt-4">
      <button
        v-if="task"
        class="delete-btn mr-4"
        @click="deleteTask"
      >
        Delete
      </button>
      <button
        class="grey-btn mr-4"
        @click="$emit('cancel')"
      >
        Cancel
      </button>
      <button
        class="green-btn"
        @click="task ? updateTask() : createTask()"
      >
        Save
      </button>
    </div>
  </div>
</template>

<script>
import FormGroup from './FormGroup'
import FormInput from './FormInput'
import { createWorkflowTask, deleteWorkflowTask, updateWorkflowTask } from '../api/WorkflowTasks'

export default {
  name: 'WorkflowTaskEditor',
  components: { FormInput, FormGroup },
  emits: ['cancel', 'created', 'saved', 'updated'],
  props: {
    workflow: {
      type: Object,
      required: true,
    },
    phase: {
      type: Object,
      required: true
    },
    task: {
      type: Object,
      default: null
    }
  },
  data: () => {
    return {
      name: '',
      scheduleDateValue: 0,
      reminderDateValue: 0,
      scheduleType: 'phase-start',
      scheduleTypes: [
        {
          label: 'After initiating this phase',
          value: 'phase-start'
        },
        {
          label: 'Before project date',
          value: 'before-project'
        },
        {
          label: 'After project date',
          value: 'after-project'
        }
      ],
      scheduleDateType: 'days',
      scheduleDateTypes: [
        {
          label: 'Days',
          value: 'days'
        },
        {
          label: 'Weeks',
          value: 'weeks'
        },
        {
          label: 'Months',
          value: 'months'
        },
        {
          label: 'Years',
          value: 'years'
        },
      ]
    }
  },
  computed: {
    mode() {
      return this.task ? 'edit' : 'create'
    }
  },
  methods: {
    handleClick() {
      this.task ? this.updateTask() : this.createTask()
    },
    async createTask() {
      const res = this.handleErrors(
        await createWorkflowTask(this.workflow.id, this.serialize())
      )

      if (!res) return

      this.$emit('created', res)
      this.$emit('saved', res)
    },
    async updateTask() {
      const res = this.handleErrors(
        await updateWorkflowTask(this.workflow.id, this.task.id, this.serialize())
      )

      if (!res) return

      this.$emit('updated', res)
      this.$emit('saved', res)
    },
    async deleteTask() {
      const confirmed = await this.$alert.confirm({
        title: 'Are you sure you want to delete?',
        text: 'This task will be deleted permanently.',
      })

      if (!confirmed) return

      const res = this.handleErrors(
        await deleteWorkflowTask(this.workflow.id, this.task.id)
      )

      if (!res) return

      this.$emit('deleted', res)
    },
    serialize() {
      return {
        name: this.name,
        phase: this.phase.slug,
        schedule_type: this.scheduleType,
        schedule_date_value: this.scheduleDateValue,
        schedule_date_type: this.scheduleDateType,
        reminder_date_value: this.reminderDateValue,
      }
    },
    hydrate() {
      this.name = this.task.name

      if (this.task.schedule_date_value) {
        this.scheduleDateValue = this.task.schedule_date_value
      }

      if (this.task.schedule_type) {
        this.scheduleType = this.task.schedule_type
      }

      if (this.task.schedule_date_type) {
        this.scheduleDateType = this.task.schedule_date_type
      }

      if (this.task.reminder_date_value) {
        this.reminderDateValue = this.task.reminder_date_value
      }
    }
  },
  mounted() {
    if (this.task) {
      this.hydrate()
    }
  }
}
</script>

<style scoped>
</style>
