<template>
  <button
    class="phase-editor text-left rounded focus:outline-none focus:shadow-outline"
    @click="$emit('click')"
  >
    <v-card
      :class="{
        'p-6': true,
        'pb-0 shadow': open
      }"
      :border="open ? 'border-grey' : undefined"
    >
      <div v-if="open">
        <div class="flex justify-between items-center border-b border-grey-lighter pb-4">
          <div class="text-base font-bold">
            {{ phase.name }}
          </div>
          <div
            v-if="actions.length"
          >
            <span class="mr-2">Actions</span>
            <progress-dots
              :progress="0"
              :total="actions.length"
            />
          </div>
        </div>
        <div class="flex flex-col md:flex-row">
          <div class="pt-4 py-6  border-grey-lighter border-b md:border-b-0 md:border-r md:w-1/2 md:mr-4">
            <div class="mb-6">
              Your actions
            </div>
            <div
              v-if="actions.length > 0"
            >
              <div class="actions-line" />
              <ul>
                <li
                  v-for="(action, index) in sortedActions"
                  :key="action.id"
                  class="mb-4"
                >
                  <action-list-item
                    :number="index + 1"
                    :action="action"
                    @click="handleEditAction(action)"
                  />
                </li>
              </ul>
            </div>

            <action-list-button
              @click="handleAddAction"
            >
              Add action
            </action-list-button>
          </div>
          <div class="pt-4 py-6 md:w-1/2">
            <div class="mb-6">
              Your tasks
            </div>
            <ul
              v-if="tasks.length > 0"
            >
              <li
                v-for="task in sortedTasks"
                :key="task.id"
                class="mb-4"
              >
                <task-list-item
                  :task="task"
                  @click="handleEditTask(task)"
                />
              </li>
            </ul>
            <task-list-button
              class="mt-2"
              @click="handleAddTask"
            >
              + Add a task
            </task-list-button>
          </div>
        </div>
      </div>

      <div
        v-else
        class="flex justify-between items-center"
      >
        <div class="text-base">
          {{ phase.name }}
        </div>
        <div
          v-if="actions.length"
        >
          <span class="mr-2">Actions</span>
          <progress-dots
            :progress="0"
            :total="actions.length"
          />
        </div>
      </div>

      <slide-modal
        :open="[modes.ADD_ACTION, modes.EDIT_ACTION].includes(currentMode)"
        @background-click="handleClose"
      >
        <workflow-action-editor
          :workflow="workflow"
          :phase="phase"
          :action="currentAction"
          @cancel="handleClose"
          @deleted="handleSaved('actions')"
          @saved="handleSaved('actions')"
        />
      </slide-modal>

      <slide-modal
        :open="[modes.ADD_TASK, modes.EDIT_TASK].includes(currentMode)"
        @background-click="handleClose"
      >
        <workflow-task-editor
          :workflow="workflow"
          :phase="phase"
          :task="currentTask"
          @cancel="handleClose"
          @deleted="handleSaved('tasks')"
          @saved="handleSaved('tasks')"
        />
      </slide-modal>
    </v-card>
  </button>
</template>

<script>
import VCard from './VCard/VCard'
import ActionListButton from './ActionListButton'
import TaskListButton from './TaskListButton'
import SlideModal from './SlideModal'
import WorkflowActionEditor from './WorkflowActionEditor'
import WorkflowTaskEditor from './WorkflowTaskEditor'
import TaskListItem from './TaskListItem'
import ActionListItem from './ActionListItem'
import ProgressDots from './ProgressDots'

const MODES = {
  NONE: 'NONE',
  ADD_ACTION: 'ADD_ACTION',
  EDIT_ACTION: 'EDIT_ACTION',
  ADD_TASK: 'ADD_TASK',
  EDIT_TASK: 'EDIT_TASK'
}

export default {
  name: 'PhaseEditor',
  components: { ProgressDots, ActionListItem, TaskListItem, WorkflowTaskEditor, WorkflowActionEditor, SlideModal, TaskListButton, ActionListButton, VCard },
  emit: ['update:actions', 'update:tasks'],
  props: {
    workflow: {
      type: Object,
      required: true
    },
    phase: {
      type: Object,
      required: true
    },
    open: {
      type: Boolean,
      default: false
    },
    actions: {
      type: Array,
      default: () => []
    },
    tasks: {
      type: Array,
      default: () => []
    }
  },
  data: function () {
    return {
      currentMode: MODES.NONE,
      currentAction: null,
      currentTask: null,
      modes: MODES,
    }
  },
  computed: {
    sortedActions() {
      return this.sortItems(this.actions)
    },
    sortedTasks() {
      return this.sortItems(this.tasks)
    }
  },
  methods: {
    sortItems(items) {
      const immediatly = items.filter(a => a.schedule_type === null)
      const afterInitiating = items.filter(a => a.schedule_type === 'phase-start').sort((a, b) => this.getScheduleHours(a) - this.getScheduleHours(b))
      const beforeProject = items.filter(a => a.schedule_type === 'before-project').sort((a, b) => this.getScheduleHours(b) - this.getScheduleHours(a))
      const afterProject = items.filter(a => a.schedule_type === 'after-project').sort((a, b) => this.getScheduleHours(a) - this.getScheduleHours(b))

      return [...new Set([...immediatly, ...afterInitiating, ...beforeProject, ...afterProject, ...items])]
    },
    getScheduleHours(item) {
      switch (item.schedule_date_type) {
        case 'horus': return item.schedule_date_value
        case 'days': return item.schedule_date_value * 24
        case 'months': return item.schedule_date_value * 24 * 30
        case 'years': return item.schedule_date_value * 24 * 365
      }
    },
    handleAddAction() {
      this.currentAction = null
      this.currentMode = MODES.ADD_ACTION
    },
    handleEditAction(action) {
      this.currentAction = action
      this.currentMode = MODES.EDIT_ACTION
    },
    handleAddTask() {
      this.currentMode = MODES.ADD_TASK
    },
    handleEditTask(task) {
      this.currentMode = MODES.EDIT_TASK
      this.currentTask = task
    },
    handleClose() {
      this.currentMode = MODES.NONE
      this.currentAction = null
      this.currentTask = null
    },
    handleSaved(type) {
      this.$emit(`update:${type}`)
      this.handleClose()
    }
  }
}
</script>

<style scoped>
  button {
    outline-offset: 1px;
  }

  .actions-line {
    @apply absolute border-l border-grey-df mt-4;
    margin-left: 15px;
    height: calc(100% + 1rem);
  }
</style>
