<template>
  <button
    class="
      cursor-pointer inline-flex items-center rounded-full pr-4 text-blue bg-white
    "
    @click="$emit('click')"
  >
    <span
      class="circle"
    >
      <img
        :src="require(`@/assets/img/icons/ico-add-blue.svg`)"
        alt="Add action"
      >
    </span>
    <slot />
  </button>
</template>

<script>
export default {
  name: 'ActionListButton'
}
</script>

<style scoped>
  button {
    outline-offset: 1px;
  }

  .circle {
    @apply p-1 border border-grey-df rounded-full mr-2;
    height: 31px;
    width: 31px;
  }
</style>
