<template>
  <div class="container mx-auto my-8 px-2">
    <h1 class="font-bold text-2xl mb-4">
      Manage workflow
    </h1>

    <div v-if="!loading">
      <v-card
        class="flex items-center justify-between px-4 py-2"
      >
        <div class="flex items-center">
          <div
            class="w-6 h-6 rounded-full mr-4"
            :style="`background-color: ${color}`"
          />
          {{ workflow.name }}
        </div>
        <div>
          <icon-button
            icon="cog"
            @click="editing = true"
          />
        </div>
      </v-card>

      <div class="ml-8">
        <div class="">
          <div
            v-for="(phase, index) in workflow.phases"
            :key="phase.slug"
            class="flex"
          >
            <div class="flex flex-col">
              <div class="line h-8" />
              <div
                class="phase-circle"
              />
              <div
                v-if="(index + 1) < workflow.phases.length"
                class="line flex-1"
              />
            </div>

            <phase-editor
              v-if="workflow"
              :phase="phase"
              :workflow="workflow"
              :actions="actions.filter(action => action.phase === phase.slug)"
              :tasks="tasks.filter(task => task.phase === phase.slug)"
              :open="currentPhase === index"
              class="ml-6 flex-1"
              :class="{
                'mt-2': index !== 0,
                'mt-4': index === 0,
              }"
              @click="currentPhase = index"
              @update:actions="loadActions"
              @update:tasks="loadTasks"
            />
          </div>
        </div>
      </div>
    </div>

    <div
      v-else
      class="py-8"
    >
      <loader />
    </div>

    <div class="mt-8 flex justify-end">
      <router-link
        to="/templates/workflows"
        class="grey-btn"
      >
        Back
      </router-link>
    </div>

    <slide-modal
      :open="editing"
      @background-click="editing = false"
    >
      <workflow-editor
        :workflow="workflow"
        @cancel="editing = false"
        @saved="handleSaved"
      />
    </slide-modal>
  </div>
</template>

<script>
import VCard from '../../components/VCard/VCard'
import { getWorkflow } from '../../api/Workflows'
import { workflowColors } from '../../utils/workflows'
import IconButton from '../../components/IconButton'
import PhaseEditor from '../../components/PhaseEditor'
import { getWorkflowTasks } from '../../api/WorkflowTasks'
import { getWorkflowActions } from '../../api/WorkflowAction'
import SlideModal from '../../components/SlideModal'
import WorkflowEditor from '../../components/WorkflowEditor'
import Loader from '../../components/Loader'

export default {
  name: 'TemplatesManageWorkflow',
  components: { Loader, WorkflowEditor, SlideModal, PhaseEditor, IconButton, VCard },
  data() {
    return {
      loading: true,
      workflow: null,
      currentPhase: 0,
      tasks: [],
      actions: [],
      editing: false
    }
  },
  computed: {
    color() {
      if (this.workflow && this.workflow.color && workflowColors.includes(this.workflow.color)) {
        return this.workflow.color
      }

      return workflowColors[0]
    }
  },
  async mounted() {
    await this.loadWorkflow()
    await Promise.all([
      this.loadTasks(),
      this.loadActions(),
    ])
    this.loading = false
  },
  methods: {
    async loadActions() {
      const res = this.handleErrors(await getWorkflowActions(this.workflow.id))
      if (!res) return
      this.actions = res.data.data
    },
    async loadTasks() {
      const res = this.handleErrors(await getWorkflowTasks(this.workflow.id))
      if (!res) return
      this.tasks = res.data.data
    },
    async loadWorkflow() {
      const res = this.handleErrors(await getWorkflow(this.$route.params.workflowId))
      if (!res) return
      this.workflow = res.data.data
    },
    handleSaved() {
      this.loadWorkflow()
      this.editing = false
    }
  },
}
</script>

<style scoped>
  .line {
    @apply border-l border-grey-df;
  }

  .phase-circle {
    @apply bg-white border border-blue-washed-grey rounded-full;
    position: relative;
    left: -8px;
    height: 17px;
    width: 17px;
  }
</style>
