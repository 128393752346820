<template>
  <input
    class="reset p-3 border transition focus:shadow-outline focus:border-grey-medium shadow-grey border-grey rounded"
    :value="value"
    @input="$emit('input', $event.target.value)"
  >
</template>

<script>
export default {
  name: 'FormInput',
  props: {
    value: {
      type: [String, Number],
      default: ''
    }
  }
}
</script>

<style scoped>

</style>
