import { client } from './VOClient'

/**
 * Create a workflow action
 *
 * @param {number} workflowId
 * @param {object} data
 * @param {string} data.name
 * @param {string} data.phase
 * @param {string} data.actionable_type
 * @param {string} data.actionable_id
 * @param {string} data.template_mail_id
 * @param {boolean} data.automatic
 * @param {number} data.schedule_date_value
 * @param {string} data.schedule_date_type
 */
export const createWorkflowAction = (workflowId, data) => {
  return client.post(`workflows/${workflowId}/actions`, data)
}

/**
 * Update a workflow action
 *
 * @param {number} workflowId
 * @param {number} workflowActionId
 * @param {object} data
 * @param {string} data.name
 * @param {string} data.phase
 * @param {string} data.actionable_type
 * @param {string} data.actionable_id
 * @param {string} data.template_mail_id
 * @param {boolean} data.automatic
 * @param {number} data.schedule_date_value
 * @param {string} data.schedule_date_type
 */
export const updateWorkflowAction = (workflowId, workflowActionId, data) => {
  return client.put(`workflows/${workflowId}/actions/${workflowActionId}`, data)
}

export const getWorkflowActions = (workflowId, phase = null) => {
  return client.get(`workflows/${workflowId}/actions`, { phase })
}

export const deleteWorkflowAction = (workflowId, workflowActionId) => {
  return client.delete(`workflows/${workflowId}/actions/${workflowActionId}`)
}
