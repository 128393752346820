<template>
  <div class="flex items-center bg-blue-69 rounded-full p-1/2 pr-1 text-white text-3xs font-bold leading-none">
    <icon
      v-if="icon"
      class="mr-1/2"
      :icon="icon"
    />
    <div class="slot-container">
      <slot />
    </div>
  </div>
</template>

<script>
import Icon from './Icon'
export default {
  name: 'Badge',
  components: { Icon },
  props: {
    icon: {
      type: String,
      default: null,
    },
  }
}
</script>

<style scoped>

</style>
