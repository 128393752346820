import { client } from './VOClient'

/**
 * Create a workflow task
 *
 * @param {number} workflowId
 * @param {object} data
 * @param {string} data.name
 * @param {string} data.phase,
 * @param {string} data.schedule_type
 * @param {number} data.schedule_date_value
 * @param {string} data.schedule_date_type
 * @param {number} data.reminder_date_value
 */
export const createWorkflowTask = (workflowId, data) => {
  return client.post(`workflows/${workflowId}/tasks`, data)
}

/**
 * Update a workflow task
 *
 * @param {number} workflowId
 * @param {number} workflowTaskId
 * @param {object} data
 * @param {string} data.name
 * @param {string} data.phase,
 * @param {string} data.schedule_type
 * @param {number} data.schedule_date_value
 * @param {string} data.schedule_date_type
 * @param {number} data.reminder_date_value
 */
export const updateWorkflowTask = (workflowId, workflowTaskId, data) => {
  return client.put(`workflows/${workflowId}/tasks/${workflowTaskId}`, data)
}

export const deleteWorkflowTask = (workflowId, workflowTaskId, data) => {
  return client.delete(`workflows/${workflowId}/tasks/${workflowTaskId}`, data)
}

export const getWorkflowTasks = (workflowId, phase = null) => {
  return client.get(`workflows/${workflowId}/tasks`, { phase })
}
