<template>
  <div class="mb-8 form-group">
    <div
      class="flex flex-col md:flex-row"
      :class="{
        'md:items-center': alignment === 'center',
        'md:items-start': alignment === 'top',
      }"
    >
      <div class="whitespace-no-wrap font-bold mb-2 md:mb-0 md:mr-8 md:w-32 md:text-right">
        {{ label }}
      </div>
      <div :class="['flex-1', inputContainerClass]">
        <slot />
      </div>
    </div>
    <div
      v-if="!!$slots.bottom"
      class="flex"
    >
      <div class="mr-8 w-32 hidden md:block"/>
      <slot name="bottom" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'FormGroup',
  props: {
    label: {
      type: String,
      default: ''
    },
    inputContainerClass: {
      type: String,
      default: ''
    },
    alignment: {
      type: String,
      default: 'center'
    }
  }
}
</script>

<style scoped>

</style>
