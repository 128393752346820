<template>
  <div>
    <h2 class="font-bold">
      <span class="font-normal">{{ phase.name }} － </span>
      <span v-if="action">Edit action</span>
      <span v-else>Add action</span>
    </h2>

    <div class="mt-16">
      <form-group
        label="Name:"
      >
        <form-input
          v-model="name"
          type="text"
          class="w-full"
          placeholder="E.g. send thank you email"
        />
      </form-group>

      <form-group
        label="What:"
      >
        <div
          v-if="actionableTypes.length > 1"
          class="select-container flex-1"
        >
          <select
            v-model="actionableType"
            @change="resetActionableId"
          >
            <option
              v-for="type in actionableTypes"
              :key="type.value"
              :value="type.value"
            >
              {{ type.label }}
            </option>
          </select>
        </div>
      </form-group>

      <form-group>
        <div class="flex items-center custom-checkbox">
          <input
            id="automatic"
            v-model="automatic"
            class="styled-checkbox mr-3"
            type="checkbox"
          >
          <label
            class="flex items-center"
            for="automatic"
          >
            <Icon
              class="mr-2"
              icon="refresh"
            />
            Send out automatically
          </label>
        </div>
      </form-group>

      <form-group
        label="Email:"
      >
        <select-email-template
          v-model="emailTemplate"
        />
      </form-group>

      <form-group
        v-if="actionableType === 'template-questionnaire'"
        label="Questionnaire:"
      >
        <select-questionnaire-template
          v-model="actionableTemplate"
        />
      </form-group>

      <form-group
        v-if="actionableType === 'template-proposal'"
        label="Proposal:"
      >
        <select-proposal-template
          v-model="actionableTemplate"
        />
      </form-group>

      <form-group
        label="When:"
      >
        <div class="select-container">
          <select v-model="scheduled">
            <option :value="false">Immediately</option>
            <option :value="true">Scheduled</option>
          </select>
        </div>
        <template
          v-if="scheduled"
          v-slot:bottom
        >
          <div class="flex mt-2">
            <div class="mr-2">
              <form-input
                v-model="scheduleDateValue"
                type="number"
                min="0"
              />
            </div>

            <div class="select-container mr-2">
              <select v-model="scheduleDateType">
                <option
                  v-for="type in scheduleDateTypes"
                  :key="type.value"
                  :value="type.value"
                >
                  {{ type.label }}
                </option>
              </select>
            </div>

            <div class="select-container">
              <select v-model="scheduleType">
                <option
                  v-for="type in scheduleTypes"
                  :key="type.value"
                  :value="type.value"
                >
                  {{ type.label }}
                </option>
              </select>
            </div>
          </div>
        </template>
      </form-group>
    </div>

    <div class="flex justify-end mt-8 border-t border-grey-lighter pt-4">
      <button
        v-if="action"
        class="delete-btn mr-4"
        @click="deleteAction"
      >
        Delete
      </button>
      <button
        class="grey-btn mr-4"
        @click="$emit('cancel')"
      >
        Cancel
      </button>
      <button
        class="green-btn"
        @click="handleClick"
      >
        Save
      </button>
    </div>
  </div>
</template>

<script>
import FormGroup from './FormGroup'
import FormInput from './FormInput'
import SelectEmailTemplate from './SelectEmailTemplate'
import SelectQuestionnaireTemplate from './SelectQuestionnaireTemplate'
import { createWorkflowAction, deleteWorkflowAction, updateWorkflowAction } from '../api/WorkflowAction'
import Icon from './Icon'
import SelectProposalTemplate from './SelectProposalTemplate'

export default {
  name: 'WorkflowActionEditor',
  components: { SelectProposalTemplate, Icon, SelectQuestionnaireTemplate, SelectEmailTemplate, FormInput, FormGroup },
  emits: ['cancel', 'created', 'saved', 'updated'],
  props: {
    workflow: {
      type: Object,
      required: true,
    },
    phase: {
      type: Object,
      required: true
    },
    action: {
      type: Object,
      default: null
    }
  },
  data: function () {
    return {
      name: '',
      automatic: false,
      emailTemplate: null,
      actionableType: null,
      actionableTemplate: null,
      scheduled: false,
      scheduleType: 'phase-start',
      scheduleDateType: 'days',
      scheduleDateValue: 0,
      actionableTypes: [
        {
          label: 'Send email',
          value: null,
        },
        {
          label: 'Send questionnaire',
          value: 'template-questionnaire'
        },
        {
          label: 'Send proposal',
          value: 'template-proposal'
        }
      ],
      scheduleTypes: [
        {
          label: 'After initiating this phase',
          value: 'phase-start'
        },
        {
          label: 'Before project date',
          value: 'before-project'
        },
        {
          label: 'After project date',
          value: 'after-project'
        }
      ],
      scheduleDateTypes: [
        {
          label: 'Hours',
          value: 'hours'
        },
        {
          label: 'Days',
          value: 'days'
        },
        {
          label: 'Weeks',
          value: 'weeks'
        },
        {
          label: 'Months',
          value: 'months'
        },
        {
          label: 'Years',
          value: 'years'
        },
      ]
    }
  },
  mounted() {
    this.hydrate()
  },
  methods: {
    resetActionableId() {
      this.actionableTemplate = null
    },
    handleClick() {
      this.action ? this.updateAction() : this.createAction()
    },
    async updateAction() {
      const res = this.handleErrors(
        await updateWorkflowAction(this.workflow.id, this.action.id, this.serialize())
      )

      if (!res) return

      this.$emit('updated', res)
      this.$emit('saved', res)
    },
    async createAction() {
      const res = this.handleErrors(
        await createWorkflowAction(this.workflow.id, this.serialize())
      )

      if (!res) return

      this.$emit('created', res)
      this.$emit('saved', res)
    },
    async deleteAction() {
      const confirmed = await this.$alert.confirm({
        title: 'Are you sure you want to delete?',
        text: 'This action will be deleted permanently.',
      })

      if (!confirmed) return

      const res = this.handleErrors(
        await deleteWorkflowAction(this.workflow.id, this.action.id)
      )

      if (!res) return

      this.$emit('deleted')
    },
    serialize() {
      return {
        name: this.name,
        phase: this.phase.slug,
        automatic: this.automatic,
        template_mail_id: this.emailTemplate,
        actionable_type: this.actionableType,
        actionable_id: this.actionableType ? this.actionableTemplate : null,
        scheduled: this.scheduled,
        schedule_type: this.scheduled ? this.scheduleType : null,
        schedule_date_type: this.scheduled ? this.scheduleDateType : null,
        schedule_date_value: this.scheduled ? this.scheduleDateValue : null,
      }
    },
    hydrate() {
      if (this.action) {
        this.name = this.action.name
        this.automatic = this.action.automatic
        this.emailTemplate = this.action.template_mail_id
        this.actionableType = this.action.actionable_type
        this.actionableTemplate = this.action.actionable_id
        this.scheduled = this.action.schedule_type !== null
        if (this.scheduled) {
          this.scheduleType = this.action.schedule_type
          this.scheduleDateType = this.action.schedule_date_type
          this.scheduleDateValue = this.action.schedule_date_value
        }
      }
    },
  }
}
</script>

<style scoped>

</style>
